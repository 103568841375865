import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"
import { Link, graphql } from "gatsby"

//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"
import SqureOneMediasol from "../../components/SVG/squreonesol"
import SqureOneMediasol2 from "../../components/SVG/squreonesol2"

// img
import squreOneabout from "../../../images/uploads/squreonemedia/squreoneabout.png"
import squreOnegridbg from "../../../images/uploads/squreonemedia/squreonegridbg.png"
import squreOnebgSol from "../../../images/uploads/squreonemedia/squreonebgsol.png"
import squreOnebgSolDark from "../../../images/uploads/squreonemedia/squreonebgsoldark.png"
import squreOness1 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-1.png"
import squreOness2 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-2.png"
import squreOness3 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-3.png"
import squreOness4 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-4.png"
import squreOness5 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-5.png"
import squreOness6 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-6.png"
import squreOness7 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-7.png"
import squreOness8 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-8.png"
import squreOness9 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-9.png"
import squreOness10 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-10.png"
import squreOness11 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-11.png"
import squreOness12 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-12.png"
import squreOness13 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-13.png"
import squreOness14 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-14.png"
import squreOness15 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-15.png"
import squreOness16 from "../../../images/uploads/squreonemedia/decodeup-SOMP-slider-ss-16.png"

import addcs1 from "../../../images/uploads/decodeup-contemporary-additional-case-study.png"
import addcs2 from "../../../images/uploads/decodeup-cwatch-additional-case-study.png"

const SqureonMedia = props => {
  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang

  const [margin, setMargin] = useState()
  const flRef = useRef()

  useEffect(() => {
    let box = document.querySelector(".container")
    let width = box.offsetWidth
    let innerw = window.innerWidth
    let paddingApplied = innerw - width

    setMargin(paddingApplied)
  })

  const options = {
    lazyLoad: true,
    prevNextButtons: true,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }

  const onMouseEnter = () => {
    flRef.current.flkty.player.play()
  }
  const onMouseLeave = () => {
    flRef.current.flkty.player.stop()
  }
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "squer-one-media-production"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner  section */}
        <section className="h-full xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-2-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  Customers, Photographers, Editors, Managers & Admin’s Custom
                  Portal
                </h4>
                <h1 className="mb-3 lg:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  Luxury Real Estate Media Management System
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  From these early meetings we had a good feel for the design,
                  personality and functions this website would require to run
                  successfully. It was a challenge to create a site that has to
                  function seamlessly for three different users, but our
                  intimate knowledge of the client's needs made it feasible for
                  us to start designing a platform that met every single demand
                  of our client.
                </p>
              </div>

              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Brandon & Nick
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Missouri, USA
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      UI/UX Design, Development, SEO, Maintenance and Support,
                      Brand strategy
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Smarty, Google APIs, AWS Bucket, JavaScript, AWS Nginx,
                      Bootstrap, WordPress
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Business to Business
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner bg section */}
        <section className="squre-bg">
          <div className="container mx-auto">
            <div className="py-6-6 w-full mr-auto squre-bg-content ">
              <h1 className="pl-6-6 text-term-primary font-poppings-bold border-l-1 border-cyan">
                All real estate media under one roof
              </h1>
            </div>
          </div>
        </section>

        {/* About Project */}

        <section className="mb-60 md:mb-20 xl:mb-1-40 ">
          <div className="container mx-auto">
            <div className="pt-60 lg:pt-1-40 border-l-1 border-cyan">
              <h2 className="pl-4 mb-4 md:mb-6-6 lg:mb-14 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-left border-l-8 border-cyan">
                About Project
              </h2>
            </div>
            <div className="pl-6 flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16">
              <div className="w-full lg:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  The World is Evolving. So Should Your Website
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We started by meeting with the CEO and COO of Square One to
                  gain an understanding of their initial vision and goals. At
                  the time, they were still using a years-old system of manually
                  booking services. We asked them to detail each of the
                  challenges they were facing and what they needed from a new
                  system. We discussed specifics for archiving their site,
                  managing data and interactive scheduling.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Some of the desires they expressed on their initial list of
                  requirements included the following:
                </p>

                <ul className="mb-5 flex flex-col gap-3 md:gap-5 list-none somp-solution-ul text-term-primary font-worksans-normal text-p4 lg:text-p3 xl:text-p2">
                  <li className="ml-6 lg:ml-10 relative">Custom Scheduling</li>
                  <li className="ml-6 lg:ml-10 relative">Tagging Features</li>
                  <li className="ml-6 lg:ml-10 relative">
                    Photographer Management
                  </li>
                  <li className="ml-6 lg:ml-10 relative">Order Tracking</li>
                  <li className="ml-6 lg:ml-10 relative">
                    Large data upload & Storage Capacity - Digital order
                    delivery
                  </li>
                  <li className="ml-6 lg:ml-10 relative">Digital Payments</li>
                  <li className="ml-6 lg:ml-10 relative">
                    Calendar Scheduling
                  </li>
                  <li className="ml-6 lg:ml-10 relative">Invoice Management</li>
                  <li className="ml-6 lg:ml-10 relative">
                    Rescheduling Features
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    Cancellation Feature
                  </li>
                </ul>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The entrepreneurs meant for all of these services to be
                  available to both residential and commercial users.
                </p>
              </div>

              <div className="w-full lg:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Different Features; Different Platforms
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  After our initial discussions we determined that the clients
                  would need three main platforms for each of their target
                  audiences.
                </p>

                <ul className="mb-5 flex flex-col gap-3 md:gap-5 list-none somp-solution-ul text-term-primary font-worksans-normal text-p4 lg:text-p3 xl:text-p2">
                  <li className="ml-6 lg:ml-10 relative">
                    Part of the site where users review services and their
                    details
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    The second platform is strictly for real estate agents and
                    property owners to choose services and order applicable
                    photo services for their project.
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    The third platform serves photographers and provides the
                    ability to receive and manage photoshoot schedules from a
                    personal dashboard.
                  </li>
                </ul>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Within these platforms are three sub-categories based on user
                  access, labeled Admin, Manager and Editors. These categories
                  control what users are allowed to access and edit on the
                  backend of the site and who can edit photos before they are
                  delivered.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We custom built this entire framework with personal logins to
                  maintain secure data input and management.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* about bg image */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <img
              src={squreOneabout}
              alt="Squreone Media Decodeup Technologies"
            />
          </div>
        </section>

        {/* challnges section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40 ">
          <div className="container mx-auto">
            <div className="pb-6-6 lg:pb-0 lg:w-2/5 border-l-1 border-cyan">
              <h2 className="pl-4 mb-4 md:mb-6-6 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-left border-l-8 border-cyan">
                The Challenges
              </h2>
              <p className="pl-6 mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                At the start of this project, Brandon and Nick had a fully
                functioning website that displayed basic services. Their vision
                was strong and they knew exactly what they wanted in their new
                web presence.
              </p>
              <p className="pl-6 pb-2 lg:pb-3 xl:pb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                They knew their varied features would present a challenge. To
                understand the depth of the project, let's review a few of those
                challenges.
              </p>
            </div>
            <div className="flex flex-wrap w-full items-start">
              <div className="p-6 w-full mms:w-1/2 lg:w-1/3 border-1 border-cyan bg-cyan text-white">
                <h3 className="mb-2 lg:mb-4 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold">
                  Tagging
                </h3>
                <p className="text-p4 lg:text-p3 xl:text-p2 font-worksans-normal">
                  Notes were an important component to help each user that would
                  access the site to stay on the same page. Coordinated notes
                  were a huge part of achieving that goals and having the
                  ability to tag other users meant they could manage centralized
                  logs for customers, orders and other related data. From
                  frontend to backend, enabling a tagging feature within the
                  notes meant there was little confusion in the scheduling
                  process.
                </p>
              </div>
              <div className="w-full lg:w-1/3 flex flex-col mms:flex-row lg:flex-col">
                <div className="p-6 w-full mms:w-1/2 lg:w-full border-1 border-cyan bg-background-primary text-term-primary">
                  <h3 className="mb-2 lg:mb-4 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold">
                    Custom Orders and Assignments
                  </h3>
                  <p className="text-p4 lg:text-p3 xl:text-p2 font-worksans-normal">
                    The owners of Square One knew their customers wanted the
                    ability to manage their orders based on specific services.
                    This meant that Square One needed to be able to edit and
                    customize orders based on discussions with clients. This
                    included services and invoice information, all while
                    maintaining secure data transfer.
                  </p>
                </div>
                <div className="p-6 w-full mms:w-1/2 lg:w-full order-1 border-cyan bg-cyan text-white">
                  <h3 className="mb-2 lg:mb-4 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold">
                    Service Charges
                  </h3>
                  <p className="text-p4 lg:text-p3 xl:text-p2 font-worksans-normal">
                    Clients in the system charged various fees for different
                    services and based on variations such as quantity and more.
                    The site had to provide dynamic fee adjustments based on 36
                    services with a range of 5-10 variations each.
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-1/3 flex flex-col mms:flex-row lg:flex-col">
                <div className="p-6 w-full mms:w-1/2 lg:w-full border-cyan bg-cyan text-white order-2 mms:order-1">
                  <h3 className="mb-2 lg:mb-4 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold">
                    Scheduling Adjustments
                  </h3>
                  <p className="text-p4 lg:text-p3 xl:text-p2 font-worksans-normal">
                    It takes a lot of logistical units to create a scheduling
                    system that's customizable. However, it was clear from the
                    start that our client wanted users to have the ability to
                    log into their account and make changes to their
                    reservations at anytime based on available time slots.
                  </p>
                </div>
                <div className="p-6 w-full mms:w-1/2 lg:w-full border-1 border-cyan bg-background-primary text-term-primary order-1 mms:order-2">
                  <h3 className="mb-2 lg:mb-4 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold">
                    Custom Suggestions
                  </h3>
                  <p className="text-p4 lg:text-p3 xl:text-p2 font-worksans-normal">
                    One of the most intricate parts of the website was a custom
                    feature that suggested the best time for a user to book a
                    photoshoot based on time and weather conditions in their
                    location, all while maintaining secure data transfer.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Grid bg image */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="mb-6 md:mb-10 md:text-center">
              <h2 className="pl-3 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 inline-block text-term-primary border-l-8 border-cyan text-center">
                Grid System
              </h2>
            </div>
            <img
              src={squreOnegridbg}
              alt="Squreone Media Decodeup Technologies"
            />
          </div>
        </section>

        {/* Solution */}
        <section className="mb-60 md:mb-20">
          <div className="container mx-auto">
            <div className="mb-8 md:mb-16 md:text-center">
              <h2 className="pl-3 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 inline-block text-term-primary border-l-8 border-cyan text-center">
                The Solutions
              </h2>
            </div>

            <div className="pb-8 lg:pb-12 flex flex-wrap sm:flex-nowrap items-center gap-8 md:gap-8 lg:gap-16">
              <div className="w-full lg:w-1/2">
                <SqureOneMediasol />
              </div>
              <div className="w-full lg:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  With close collaboration, DecodeUp migrated this company from
                  a simple site for a fully automated digital powerhouse
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We refined the client's concepts and designed a site that
                  could manage multiple users, functions and files without
                  breaking under the pressure.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The solutions we crafted improved on their manual system and
                  granted the client the ability to give their users options and
                  freedom with navigating the webpage.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  One of the extras we provided to our clients was an Amazon
                  cloud integration to help with managing the large file
                  photographs necessary to deliver photographic services.
                  Digital data for the site often exceed 2500 GB and we were
                  able to facilitate a process that made uploading and
                  downloading the files fast and simple.
                </p>
              </div>
            </div>
            <div className="pb-8 lg:pb-16 flex flex-wrap sm:flex-nowrap items-center gap-8 md:gap-8 lg:gap-16">
              <div className="w-full lg:w-1/2 order-2">
                <SqureOneMediasol2 />
              </div>
              <div className="w-full lg:w-1/2 order-1">
                <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  A backend that works overtime
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  For the suggested time feature, we integrated APIs for weather
                  that demonstrated that position of the sun at certain
                  locations and times of days. We also incorporated cloud
                  coverage, atmospheric changes, chance of precipitation and
                  other data to create the most accurate time suggestions.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The dashboards for admin provide the most detailed data on
                  current orders, fulfillment, photographers, and more.
                  Photographers are able to fulfill services, assign work to
                  editors and update travel status.
                </p>
              </div>
            </div>

            <div className="w-full max-w-8-80 mx-auto text-center">
              <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                A Website that's brains and beauty
              </h3>
              <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                Last but not least, after creating the code for such a dynamic
                web space, we set our designers to work creating a modern design
                that gave the site a catchy, gorgeous look that highlighted our
                client's professionalism.
              </p>
              <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                Do you have a stellar idea that needs to be turned into reality? We
                want to help you bring those ideas to life and realize the
                possibilities of your greatest dreams.
              </p>
            </div>
            <div className="mt-16 w-full">
              <img
                className="mx-auto squre-bg-light"
                src={squreOnebgSol}
                alt="Squreone Media Decodeup Technologies"
              />
              <img
                className="mx-auto squre-bg-dark"
                src={squreOnebgSolDark}
                alt="Squreone Media Decodeup Technologies"
              />
            </div>
          </div>
        </section>

        {/* Typographics */}

        <section className="mb-60 md:mb-20 xl:mb-1-40 ">
          <div className="container mx-auto">
            <div className="mb-8 md:mb-16 sque-typo">
              <div className="mb-6 md:mb-6-6 w-full">
                <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Typography
                </h3>
              </div>
              <div className="flex w-full flex-wrap gap-6-6">
                <div className="w-full lg:w-47-0">
                  <h2 className="mb-2 lg:mb-4 text-h4 lg:text-sh2 xl:text-h2 font-poppings-bold text-term-primary">
                    Lato
                  </h2>
                  <h5 className="mb-1 text-term-primary opacity-70">
                    ABCDEFGHIJKLMNOPQRSTUVWXYZ
                  </h5>
                  <h5 className="mb-1 text-term-primary opacity-70">
                    abcdefghijklmnopqrstuvwxyz
                  </h5>
                  <h5 className="mb-1 text-term-primary opacity-70">
                    1234567890
                  </h5>
                </div>
                <div className="w-full lg:w-47-0">
                  <h2 className="mb-2 lg:mb-4 text-h4 lg:text-sh2 xl:text-h2 font-poppings-bold text-term-primary">
                    Open Sans
                  </h2>
                  <h5 className="mb-1 text-term-primary opacity-70">
                    ABCDEFGHIJKLMNOPQRSTUVWXYZ
                  </h5>
                  <h5 className="mb-1 text-term-primary opacity-70">
                    abcdefghijklmnopqrstuvwxyz
                  </h5>
                  <h5 className="mb-1 text-term-primary opacity-70">
                    1234567890
                  </h5>
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="mb-6 md:mb-6-6 w-full">
                <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Color Palette
                </h3>
              </div>
              <div className="flex flex-wrap sm:flex-nowrap gap-10 md:gap-16">
                <div className="sque-color-plate text-center">
                  <span></span>
                  <h5 className="mt-3 text-term-primary font-poppings-bold text-center">
                    #00263D
                  </h5>
                </div>
                <div className="sque-color-plate sque-color-plate-2 text-center">
                  <span></span>
                  <h5 className="mt-3 text-term-primary font-poppings-bold text-center">
                    #127ABF
                  </h5>
                </div>
                <div className="sque-color-plate sque-color-plate-3 text-center">
                  <span></span>
                  <h5 className="mt-3 text-term-primary font-poppings-bold text-center">
                    #5F6A6D
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Result section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40 ">
          <div className="container mx-auto">
            <div className="w-full sm:w-2/3 lg:max-w-3xl">
              <h2 className="mb-4 lg:mb-8 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                The Result
              </h2>
              <p className="mb-2 lg:mb-3 xl:mb-6 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                A gorgeous site. Simple navigation. Powerful features. Live
                data. A portal that has all the answers. These are just some of
                the highlights of this finished product for Square One Media
                Production that's making life easier for real estate agents and
                photographers alike. too.
              </p>
              <p className="mb-2 lg:mb-3 xl:mb-6 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                Connect users and propel your business within your budget by
                calling DecodeUp. We help business just like yours to achieve
                exceptional performance with the look and feel of a major
                enterprise corporation.
              </p>
              <p className="mb-2 lg:mb-3 xl:mb-6 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                You can do more than you've ever imagined with your site.
                Capture new customers, bring people together and make your
                workflow more efficient. No need to waste time money or energy.
              </p>

              <div className="w-full">
                <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  There are so many possibilities with DecodeUp. Find yours
                  today.
                </h3>
              </div>
            </div>
          </div>
        </section>
        {/* slider section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="case-study-slider-bg">
            <div className="container container-cor mx-auto">
              <div
                id="myid"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Flickity
                  className={"case-studies-carousel cstm-du-casestudy-slider"}
                  elementType={"div"}
                  options={options}
                  ref={flRef}
                >
                  {/* <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness1}
                    alt="squreOne Decodeup Technologies"
                  /> */}
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness2}
                    alt="squreOne Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness3}
                    alt="squreOne Decodeup Technologies"
                  />
                  {/* <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness4}
                    alt="squreOne Decodeup Technologies"
                  /> */}
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness5}
                    alt="squreOne Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness6}
                    alt="squreOne Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness7}
                    alt="squreOne Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness8}
                    alt="squreOne Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness9}
                    alt="squreOne Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness10}
                    alt="squreOne Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness11}
                    alt="squreOne Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness12}
                    alt="squreOne Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness13}
                    alt="squreOne Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness14}
                    alt="squreOne Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness15}
                    alt="squreOne Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={squreOness16}
                    alt="squreOne Decodeup Technologies"
                  />
                </Flickity>
              </div>
            </div>
          </div>
        </section>

        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* additional case studies section */}

        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/dental-health-care-website"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Dental Health Care Website

                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs1}
                      alt="Contemporary Dentistry Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
              <div className=" pt-36 w-full sm:w-1/2 text-center bg-background-case">
                <Link
                  // to={post.fields.slug}
                  to="/case-study/truck-drivers-security-app"
                  className="h-full flex items-center justify-between flex-col text-center"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Truck Driver's Security App
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs2}
                      alt="C-watch Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  )
}

export default SqureonMedia

export const pageQuery = graphql`
query saquareonemediaquery {
  pageData: allMarkdownRemark(
    filter: {
      fileAbsolutePath: {
        regex: "/case-studies/luxury-real-estatemedia-managements.md/"
      }
    }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }
}

`;